@media (max-width: 339.98px) {
    .container{
        padding: 0 20px 0 20px !important;
    }
    .nav-logo{
        width: 100px;
    }
    .radio-point{
        margin-top: 10vh;
        font-size: 45px;
    }
    .radio-play-pause{
        font-size: 52px;
    }
    .radio-name{
        font-size: 32px;
    }
    .radio-des{
        font-size:15px;

    }
}
@media (min-width:340px) and (max-width: 360.98px) {
    .container{
        padding: 0 20px 0 20px !important;
    }
    .nav-logo{
        width: 100px;
    }
    .radio-point{
        margin-top: 10vh;
        font-size: 45px;
    }
    .radio-play-pause{
        font-size: 52px;
    }
    .radio-name{
        font-size: 32px;
    }
    .radio-des{
        font-size:15px;

    }
}
@media (min-width: 361px) and (max-width: 575.98px) {
    .container{
        padding: 0 20px 0 20px !important;
    }
    .nav-logo{
        width: 100px;
    }
    .radio-point{
        margin-top: 10vh;
        font-size: 45px;
    }
    .radio-play-pause{
        font-size: 52px;
    }
    .radio-name{
        font-size: 32px;
    }
    .radio-des{
        font-size:15px;

    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .container{
        padding: 0 20px 0 20px !important;
    }
    .nav-logo{
        width: 100px;
    }
    .radio-point{
        margin-top: 10vh;
        font-size: 45px;
    }
    .radio-play-pause{
        font-size: 52px;
    }
    .radio-name{
        font-size: 32px;
    }
    .radio-des{
        font-size:15px;

    }
}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {


}

@media (min-width: 1200px) {


}
