body {
    font-family: 'Poppins', sans-serif !important;
    background-image: url("../../assets/images/background.jpg");
    background-color: #f0f0f0;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    font-size: 14px;
}
.navbar{
    background-color: transparent !important;
}
.nav-logo{
    width: 140px;
}
.nav-link{
    color: #ffffff !important;
    margin: 1px 10px 1px 10px !important;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    transition: 0.2s ease-in;
    border-bottom: 3px solid  transparent;
}
.nav-link-active{
    color: #ffffff !important;
    margin: 1px 10px 1px 10px !important;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    transition: 0.2s ease-in;
    border-bottom: 3px solid  #f0f0f0;
}
.nav-link:hover{
    transition: 0.2s ease-in;
    color: #f0f0f0 !important;
    border-bottom: 3px solid  #f0f0f0;
}
.nav-link-social{
    font-size: 24px;
    margin: 1px 6px 1px 6px !important;
    font-weight: 500;
    transition: 0.2s ease-in;
    color: #ffffff !important;
}
.nav-link-social:hover{
    color: #f0f0f0 !important;
    transition: 0.2s ease-in;
}
.navbar-toggler:focus{
    outline: none;
    border: none;
    box-shadow: none;
}
.container{
    padding: 0 10px 0 10px !important;
}
.radio-point{
    margin-top: 15vh;
    font-family: 'Poppins', sans-serif !important;
    font-size: 80px;
    font-weight: 600;
    color: #ffffff !important;
}
.radio-play-pause{
    transition: 0.2s ease-in;
    cursor: pointer;
    font-size: 92px;
    color: #ffffff !important;
}
.radio-play-pause:hover{
    transition: 0.2s ease-in;
    color:  #f0f0f0 !important;
}
.radio-name{
    font-size: 48px;
    font-weight: 500;
    color: #ffffff !important;
}
.radio-des{
    font-size:18px;
    font-weight: 300;
    color: #ffffff !important;
}
.card{
    border-radius: 2px;
    background: #ffffff;
    border: none;
    transition: 0.2s;
    box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,.132),0 .3px .9px 0 rgba(0,0,0,.108);
}


